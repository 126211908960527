import dark from './dark-theme'
import light from './light-theme'

export const getPreferredTheme = (mode: 'dark' | 'light') => {
  if (mode === 'dark') {
    return dark
  } else {
    return light
  }
}
