import React, { FC } from 'react'
import { Box, Card, CardContent, CardHeader, CssBaseline, ThemeProvider, Typography } from '@mui/material'
import styles from './App.module.css'
import utilStyles from './assets/css/utility.module.css'
import logo from './assets/img/logo-full-web.png'
import { getPreferredTheme } from './themes'

type AppProps = {
    id?: string;
}

const App: FC<AppProps> = () => {
  const selectedTheme = getPreferredTheme('dark')
  return (
        <ThemeProvider theme={selectedTheme}>
            <CssBaseline/>
            <div className={styles.layoutContainer}>
                <header className={styles.header} role={'banner'}>
                    <img src={logo} className={styles.logo} alt={'Ejafi software logo'}/>
                </header>
                <section className={styles.contentArea} role={'main'}>
                    <Typography className={styles.gameTitle} variant={'h3'} component={'h1'} fontWeight={100}>
                        Deep Space Expedition 47
                    </Typography>
                    <Box className={[utilStyles.blur, styles.gameInfo].join(' ')}>
                        <Card>
                            <CardHeader title={'Machine Learning'} className={styles.gameInfoCardHeader}/>
                            <CardContent>
                                Even though you&apos;re deep in space, you&apos;re not alone in the universe.
                                You&apos;ll need to buy and sell goods from intelligent, AI competitors powered by
                                machine learning. These competitors have their own goals which may -- or may not --
                                align with yours.
                            </CardContent>
                        </Card>

                        <Card>
                            <CardHeader title={'Detailed Crafting and Trading'} className={styles.gameInfoCardHeader}/>
                            <CardContent>
                                You will have to interact with the Galactic Exchange to prepare your colony for
                                occupation. Here, you&apos;ll buy and sell commodities in a simulation of real-world
                                market behavior. Your trading partners and competitors will employ true-to-life pricing
                                and purchasing behaviors. The quality of the goods you manufacture is only as good as
                                the raw materials used to build them. Mine or buy the best!
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader title={'Grow Your Organization'} className={styles.gameInfoCardHeader}/>
                            <CardContent>
                                The job of setting up a stable supply chain and economy is too big for one person. Hire
                                employees to help manage your operations, harvest raw materials, or buy and sell
                                commodities. Virtually all aspects of your organization can be delegated to an employee.
                                Once assigned, you can build objectives and plans for your employees; each employee is
                                unique, so employ them wisely!
                            </CardContent>
                        </Card>
                    </Box>
                    <Box className={[utilStyles.blur, styles.gameTagline].join(' ')}>
                        <Typography variant={'h4'} component={'h2'}>
                            Prepare to lead
                        </Typography>
                    </Box>
                </section>
                <footer className={styles.footer} role={'contentinfo'}>
                    <Typography>Copyright &copy; {new Date().getFullYear()} Ejafi Software</Typography>
                </footer>
            </div>
        </ThemeProvider>
  )
}

export default App
