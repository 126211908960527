import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5eb861',
      contrastText: '#f0f4c3'
    },
    secondary: {
      main: '#669ab7',
      contrastText: '#cfd8dc'
    },
    background: {
      default: '#eeeeee',
      paper: '#fafafa'
    },
    text: {
      primary: '#669ab7'
    }
  },
  typography: {
    fontFamily: '"Exo 2", sans-serif'
  }
})

export default theme
