import { createSlice } from '@reduxjs/toolkit'

export interface UserState {
}

export const initialState: UserState = {}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {}
})

export default userSlice.reducer
